<template>
  <div>
    <!-- <b-navbar toggleable="md" type="dark" variant="primary" class="fixed-top topo ">
      <b-navbar-brand href="#">
        <img src="~@/assets/css/novo_layout/images/logo_talk_inicio.png" class="logo-img" alt="Logo">
        <div class="menu-btn-respons-container2">       
          <a class="fs-primary-nav-trigger" href="#">
            <i class="fa fa-sign-out fa-1x" style="color:#fff" title="Sair"></i>
            <span style="color:#fff">Sair</span>
          </a>
        </div>
      </b-navbar-brand> -->
     <!--  <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="isLoggedIn"><a @click="logout">Logout</a></b-nav-item>
        </b-navbar-nav>
      </b-collapse> -->
    </b-navbar>
    <router-view></router-view>
  </div>
</template>
<script>

// import LayoutHeader from '@/components/layout/Header'
// import Auth from '@/api/auth-api'
export default {
  data () {
    return {
      estado: JSON.parse(localStorage.getItem("estado"))
    }
  },
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    }
  },
  beforeCreate(){
    
    
  },
  created:function(){
    if(!this.$store.getters.isLoggedIn){
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    }
  },
  mounted(){
    var vuex={
        status: this.$store.state.status,
        token: this.$store.state.token,
        id_cadastro:this.$store.state.id_cadastro,
        id_qualificacao:this.$store.state.id_qualificacao,
        acesso:this.$store.state.acesso,
        nome:this.$store.state.nome,
        avatar:this.$store.state.avatar
    }
    var local = JSON.parse(localStorage.getItem('estado'))

    if(JSON.stringify(vuex) !== JSON.stringify(local)){
      this.$store.dispatch('persist',{token:this.$store.state.token})
    }
  }
}
</script>
<style>
.topo .logo-img {
  height: 42px;
  margin:0;
}
.topo .fs-primary-nav-trigger {
  position: absolute;
  right: 0;
  top: 15px;
  height: 100%;
  width: 50px;
}

</style>