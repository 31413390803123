<template>
  <div class="chat">
     <b-navbar toggleable="md" type="dark" class="fixed-top topo" style="background-color: #008000;position:fixed;top:0;">
      <b-navbar-brand href="#">
        <img src="~@/assets/css/novo_layout/images/logo_talk_inicio.png" class="logo-img" alt="Logo">
        <div class="menu-btn-respons-container2">       
          <a class="fs-primary-nav-trigger" v-if="$parent.isLoggedIn" @click="$parent.logout">
            <i class="fa fa-sign-out" style="color:#fff; font-size:1.50em;position:absolute;top: 0;left: 0;" title="Sair"></i>
            <br>
            <span  style="color:#fff;font-size:15px;position:absolute;top: 26px;left: 0;">Sair</span>
          </a>
        </div>
      </b-navbar-brand>
    </b-navbar>
    <div class="topo">
      <span class="voltar"><router-link to="/home">Voltar</router-link></span>
      <span class="grupo">{{titulo}}</span>
    </div>
    <div class="conversa">
      <div v-if="$parent.estado.acesso=='user_admin'" class="input-group">
        <span class="input-group-btn">
          <button @click="buscarHistorico(id_cadastro)" class="btn btn-primary" type="button">Procurar</button>
        </span>
        <input type="number" v-model="id_cadastro" class="form-control" placeholder="Procurar por...">
      </div>
      <ul class="list list-chats">          
        <li v-if="historico" v-for="c of chat" class="list-chat w-clearfix">
          <div v-if="c.id_cadastro != $parent.estado.id_cadastro">
            <div class="chat column-left">
              <div class="chat image-message">
                <img v-if="c.avatar" :src="c.avatar">
                <img v-else src="~@/assets/css/novo_layout/images/icones/1.png">
              </div>
            </div>
            <div class="chat column-right w-clearfix">
              <div class="arrow-globe"></div>
              <div class="chat-text">
                <span class="participante"><strong>{{c.nome}}</strong></span>
                <span v-if="c.url">
                  <span class="participante"></span>
                  <img :src="c.url">
                </span>
                <p v-else v-html="destacar(c.mensagem)"></p>                
              </div>
              <div class="chat-time">{{c.data_insercao}}</div>
            </div>
          </div>
          <div v-else class="chat column-right right w-clearfix">
            <div class="arrow-globe right"></div>
            <div class="chat-text right">
              <span v-if="c.url">
                <span class="participante"></span>
                <img :src="c.url">
              </span>
              <p v-else v-html="destacar(c.mensagem)"></p>
            </div>
            <div class="chat-time right">{{c.data_insercao}}</div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="$store.state.acesso!='user_admin'" class="input-chat-block">
      <div class="w-form">
        <form @submit.prevent="clickButton">
          <input 
          class="input-chat w-input" 
          maxlength="256" 
          placeholder="Sua mensagem" 
          required="required" 
          autocomplete="off" 
          type="text"
          v-model="msg"
          name="msg">
          <button class="chat-button">Enviar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import ChatApi from '@/api/chat-api'
  // import ArquivosApi from '@/api/arquivos-api'
  import $ from 'jquery'
  // import ImageCompressor from 'image-compressor.js'
  export default {
    data() {
      return {
        msg:'',
        user:{},
        chat:{},
        file:[],
        titulo:'',
        imagem:false,
        historico:false,
        id_chat:4,
        id_cadastro:''
      }
    },
    methods: {
      escapeHtml(text) {
        if(text)
        return text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
      },
      destacar(texto){        
        self=this
        if(texto){
          var mensagem = self.escapeHtml(texto)                
          var ct = mensagem.split("*").length -1
          if(mensagem.split("*").length>0){
            for (var i = 0; i <= ct; i++) {              
              if(i%2==0 && i>0){              
                mensagem = mensagem.replace("*","<span class='font-weight-bold'>").replace("*","</span>")          
              }            
            }                  
          }
          return mensagem
        }        
      },
      clickButton(){
        var id_cadastro = this.$parent.estado.id_cadastro
        var id_chat     = parseInt(this.id_chat)
        let mensagem    = this.msg

        ChatApi.enviarMensagem(id_cadastro,id_chat,mensagem).then((response)=>{
          let data = response.data

          var html = '<li class="list-chat w-clearfix"><div class="chat column-right right w-clearfix"><div class="arrow-globe right"></div><div class="chat-text right"><span>'+data.mensagem+'</span></div><div class="chat-time right">'+data.data_insercao+'</div></div></li>'

          $('.list.list-chats').append(html)
          $('body,html').animate({ scrollTop: $('.chat').height() }, 300)
          this.msg=''
        })
      },
      buscarHistorico(id_cadastro){

        ChatApi.historicoRedeLinear(id_cadastro).then((response)=>{
          let data = response.data          
          if(data.status=='success'){
            this.historico = true
            this.chat = data.chat
          }else{ 
            this.historico = false
            this.chat=''
          }
          setTimeout(function(){
            $('body,html').animate({ scrollTop: $('.chat').height() }, 300)
          },
          1000);          
        })
      }
    },
    mounted(){
      this.titulo = "Rede Linear"
      if(this.$parent.estado.id_cadastro>0){
        this.buscarHistorico(this.$parent.estado.id_cadastro)
      }
    },
    beforeDestroy(){
      
      ChatApi.ultimaVisualizacao(this.$parent.estado.id_cadastro,this.id_chat).then((response)=>{
        let visualizacao = response.data;
      })
    }
  }
</script>
<style>
  .conversa{
    padding-top: 100px;
  }
  .topo{
    background: #fff;
    color:#000;
    text-align: center;
    padding: 10px;
  }
  .topo .voltar{
    float: left
  }

    .participante{
      display: block;
    }
    .image-message {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 48px;
      height: 48px;
      margin-top: 7px;
      border-radius: 25px;
      background: #fff;
    }
    .chat.column-right .chat-text.right, .chat.column-right .chat-text{
      position:relative;
      max-width: 100%;
    }
    .chat.column-right .chat-text.right, .chat.column-right .chat-text{
      position:relative;
      max-width: 100%;
    }
    .chat.column-right .chat-text.right .opcoes{
      position: absolute;
      top: 2px;
      left: 7px;
    }
    .chat.column-right .chat-text .opcoes{
      position: absolute;
      top: 2px;
      right: 7px;
    }
    .chat p{
      white-space: pre-line;
      margin:0;
      word-break: break-word;
    }
    .chat.column-right .chat-text.right p{
      color:#fff !important;
    }
</style>
