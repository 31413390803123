<template>
  <div class="chat">
    <b-navbar toggleable="md" type="dark" class="fixed-top topo" style="background-color: #008000;position:fixed;top:0;">
      <b-navbar-brand href="#">
        <img src="~@/assets/css/novo_layout/images/logo_talk_inicio.png" class="logo-img" alt="Logo">
        <div class="menu-btn-respons-container2">       
          <a class="fs-primary-nav-trigger" v-if="$parent.isLoggedIn" @click="$parent.logout">
            <i class="fa fa-sign-out" style="color:#fff; font-size:1.50em;position:absolute;top: 0;left: 0;" title="Sair"></i>
            <br>
            <span  style="color:#fff;font-size:15px;position:absolute;top: 26px;left: 0;">Sair</span>
          </a>
        </div>
      </b-navbar-brand>
    </b-navbar>
    <div class="topo">
      <span class="voltar"><router-link to="/home">Voltar</router-link></span>
      <span class="grupo">{{titulo}}</span>
      <span class="favoritas">
        <button v-if="favoritas" @click="msgFavoritas">Favoritas</button>
        <button v-else @click="msgChat">Chat</button>
      </span>
    </div>
    <b-modal v-model="modalShow" size="sm" centered hide-footer hide-header title="Opções">
      <b-list-group size="sm" v-if="favoritas">        
        <b-list-group-item v-if="apagar" @click="apagarMsg(infMsg.id,infMsg.mensagem_r,infMsg.data_insercao,infMsg.url)">
          <font-awesome-icon icon="trash" size="1x"/> Apagar Mensagem
        </b-list-group-item>
        <b-list-group-item v-if="responder && !infMsg.url_r" @click="responderMsg(infMsg)">
          <font-awesome-icon icon="reply" size="1x"/> Responder Mensagem</b-list-group-item>
        <b-list-group-item v-if="favoritar && infMsg.favoritado==0" @click="favoritarMsg(infMsg.id)">
          <font-awesome-icon icon="star" size="1x"/> Favoritar Mensagem
        </b-list-group-item>
        <b-list-group-item v-if="favoritar && infMsg.favoritado==1" @click="desfavoritarMsg(infMsg.id)">
          <font-awesome-icon icon="star" size="1x"/> Desfavoritar Mensagem
        </b-list-group-item>
      </b-list-group>
      <b-list-group size="sm" v-if="!favoritas">        
        <b-list-group-item v-if="favoritar && infMsg.favoritado==1" @click="desfavoritarMsg(infMsg.id)">
          <font-awesome-icon icon="star" size="1x"/> desfavoritar Mensagem
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <div class="conversa">
      <ul class="list list-chats">
        <li v-for="c of chat" class="list-chat w-clearfix">            
          <div :id="c.id" v-if="c.origem">
            <!-- div se existir resposta -->
            <div v-if="c.origem != $store.state.id_cadastro">
              <div class="chat column-left avatar">
                <div class="chat image-message">
                  <img v-if="c.avatar" :src="c.avatar">
                  <img v-else src="~@/assets/css/novo_layout/images/icones/1.png">
                </div>
              </div>
              <!--balao de resposta-->
              <div class="chat column-right w-clearfix">                
                <div class="arrow-globe"></div>
                <div v-if="!c.removida" class="chat-text" @dblclick="showModal(c,false,true,true)">
                  <span class="opcoes">
                    <font-awesome-icon @click="showModal(c,false,true,true)" icon="angle-down"/>
                  </span>
                  <span class="participante"><strong>{{c.id_cadastro}}</strong></span>
                  <div class="chat-resposta" style="background-color: rgba(0, 0, 0, 0.05);border-radius: 7.5px;">
                    <div class="texto-origem" style="padding: 4px 12px 8px 8px;">
                      <span class="participante"><strong>{{c.id_cadastro_r}}</strong></span>
                      <p v-html="destacar(c.mensagem_r)"></p>
                    </div>  
                  </div>
                  <span v-if="c.url">
                    <span class="participante"></span>
                    <img v-if="c.url.substr(-3)!=='pdf'" :src="c.url">
                    <a v-else class="btn btn-download" :href="c.url" download target="_blank" style="color: #000;font-weight: 600;">Download arquivo PDF</a>
                  </span>
                  <p v-else v-html="destacar(c.mensagem)"></p>
                  <span v-if="c.favoritado==1" class="favoritado_esq">
                    <font-awesome-icon icon="star" size="1x"/>
                  </span>                                    
                </div> 
                <div v-else class="chat-text">
                  <p v-html="destacar(c.mensagem_r)"></p>
                </div>               
                <div class="chat-time">{{c.data_insercao}}</div>
              </div>
              <!--Fim balao de resposta-->
            </div>
            <div v-else class="chat column-right right w-clearfix">
              <div class="arrow-globe right"></div>              
              <div v-if="!c.removida" class="chat-text right" @dblclick="showModal(c,true,true,true)">       
                <span class="opcoes"><font-awesome-icon @click="showModal(c,true,true,true)" icon="angle-down"/></span>           
                <div class="chat-resposta" style="background-color: rgba(0, 0, 0, 0.05);border-radius: 7.5px;">
                  <div class="texto-origem" style="padding: 4px 12px 8px 8px;">
                    <span class="participante"><strong>{{c.id_cadastro_r}}</strong></span>
                    <p v-html="destacar(c.mensagem_r)"></p>
                  </div>  
                </div>
                <span v-if="c.url">
                  <span class="participante"></span>
                  <img v-if="c.url.substr(-3)!=='pdf'" :src="c.url">
                  <a v-else class="btn btn-download" :href="c.url" download target="_blank" style="color: #fff;font-weight: 600;">Download arquivo PDF</a>
                </span>
                <p v-else v-html="destacar(c.mensagem)"></p>
                <span v-if="c.favoritado==1" class="favoritado_dir">
                  <font-awesome-icon icon="star" size="1x"/>
                </span>                                     
              </div>
              <div v-else class="chat-text right">
                <p v-html="destacar(c.mensagem_r)"></p>
              </div>
              <div class="chat-time right">{{c.data_insercao}}</div>
            </div>              
          </div>
          <div :id="c.id" v-else>
            <div v-if="c.origem_r != $store.state.id_cadastro">
              <div class="chat column-left avatar kkk">
                <div class="chat image-message">
                  <img v-if="c.avatar_r" :src="c.avatar_r">
                  <img v-else src="~@/assets/css/novo_layout/images/icones/1.png">
                </div>
              </div>
              <div class="chat column-right w-clearfix">
                <div class="arrow-globe"></div>                
                <div v-if="!c.removida" class="chat-text" @dblclick="showModal(c,false,true,true)">
                  <span class="opcoes"><font-awesome-icon @click="showModal(c,false,true,true)" icon="angle-down"/></span>
                  <span class="participante"><strong>{{c.id_cadastro_r}}</strong></span>
                  <span v-if="c.url_r">
                    <span class="participante"></span>                  
                    <img v-if="c.url_r.substr(-3)!=='pdf'" :src="c.url_r">
                    <a v-else class="btn btn-download" :href="c.url_r" download target="_blank" style="color: #000;font-weight: 600;">Download arquivo PDF</a>
                  </span>
                  <p v-else v-html="destacar(c.mensagem_r)"></p>   
                  <span v-if="c.favoritado==1" class="favoritado_esq">
                    <font-awesome-icon icon="star" size="1x"/>
                  </span>               
                </div>
                <div v-else class="chat-text">
                  <p v-html="destacar(c.mensagem_r)"></p>
                </div>
                <div class="chat-time">{{c.data_insercao}}</div>
              </div>
            </div>
            <div v-else class="chat column-right right w-clearfix" >
              <div class="arrow-globe right"></div>              
              <div v-if="!c.removida" class="chat-text right" @dblclick="showModal(c,true,true,true)"> 
                <span  class="opcoes"><font-awesome-icon @click="showModal(c,true,true,true)" icon="angle-down"/></span>
                <span v-if="c.url_r">
                    <span class="participante"></span>
                    <img v-if="c.url_r.substr(-3)!=='pdf'" :src="c.url_r">
                    <a v-else class="btn btn-download" :href="c.url_r" download target="_blank" style="color: #fff;font-weight: 600;">Download arquivo PDF</a>
                </span>
                <p v-html="destacar(c.mensagem_r)"></p>
                <span v-if="c.favoritado==1" class="favoritado_dir">
                  <font-awesome-icon icon="star" size="1x"/>
                </span>
              </div>
              <div v-else class="chat-text right">
                <p v-html="destacar(c.mensagem_r)"></p>
              </div>
              <div class="chat-time right">{{c.data_insercao}}</div>
            </div>  
          </div>              
        </li>        
      </ul>
    </div>
    <div v-if="showAlert" class="msg-responder alert alert-warning alert-dismissible" role="alert">
      <div v-if="pergunta.id_cadastro_r && !pergunta.id_cadastro">
        <span v-if="!pergunta.url_r">
          <strong>{{pergunta.id_cadastro_r}}:</strong>
          <p v-html="destacar(pergunta.mensagem_r)"></p>
        </span>
        <span v-else>
          <span v-if="pergunta.id_cadastro_r == $store.state.nome">Você :
            <font-awesome-icon icon="camera" size="1x"/> 
          </span>
          <span v-else>{{pergunta.id_cadastro_r}}:<font-awesome-icon icon="camera" size="1x"/> </span>
          <span class="responder">
            <img :src="pergunta.url_r">  
          </span>        
        </span>
      </div>
      <div v-else>
        <span v-if="!pergunta.url">
          <strong>{{pergunta.id_cadastro}}:</strong>
          <p v-html="destacar(pergunta.mensagem)"></p>
        </span>
        <span v-else>
          <span v-if="pergunta.id_cadastro == $store.state.nome">Você :
            <font-awesome-icon icon="camera" size="1x"/> 
          </span>
          <span v-else>{{pergunta.id_cadastro}}:<font-awesome-icon icon="camera" size="1x"/> </span>
          <span class="responder">
            <img :src="pergunta.url">  
          </span>        
        </span>
      </div>      
      <button @click="fecharResposta" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div v-if="favoritas" class="input-chat-block">
      <div class="w-form">
          <form @submit.prevent="clickButton">
            <textarea 
            autocapitalize="off" 
            autocomplete="off" 
            autocorrect="off"
            class="input-chat w-input" 
            maxlength="256" 
            placeholder="Sua mensagem" 
            required="required"             
            v-model="msg"
            name="msg">
            </textarea>
            <button class="chat-button">Enviar</button>
              <b-dropdown id="ddown-dropup" dropup class="pl-1" v-if="!showAlert">
                <b-dropdown-item-button>
                  <label>
                    <font-awesome-icon icon="file" class="mr-10"/>Enviar Arquivo           
                    <input
                      type="file" 
                      @change="onFileChange"                 
                      class="custom-file-input">
                  </label>
                </b-dropdown-item-button>
                <b-dropdown-divider />
                <b-dropdown-item-button>
                  <label>
                    <font-awesome-icon icon="camera" class="mr-10"/>Enviar Foto           
                    <input
                      type="file" 
                      @change="onImageChange"                 
                      class="custom-file-input">
                  </label> 
                </b-dropdown-item-button>
              </b-dropdown>
            <!-- <label class="chat-button" style="height:38px;text-align:center">
              <span><font-awesome-icon icon="file"/></span>            
              <input
                type="file" 
                name="file"
                @change="onFileChange"                 
                class="custom-file-input">
            </label> -->
          </form>
      </div>
    </div>
  </div>
</template>

<script>
  import ChatApi from '@/api/chat-api'
  import MsgPush from '@/api/msgpush-api'
  import ArquivosApi from '@/api/arquivos-api'
  import $ from 'jquery'
  import ImageCompressor from 'image-compressor.js'
  export default {
    data() {
      return {
        msg:'',        
        user:{},
        chat:{},
        file:[],
        titulo:'',
        tag:'',
        pergunta:'',
        favoritas:true,
        showAlert:false,
        apagar:false,
        responder:false,
        favoritar:false,
        modalShow:false,
        infMsg:'',
        id_chat:this.$route.params.id_chat
      }
    },
    methods: { 
      msgChat(){
        self = this         
        self.favoritas = true
        ChatApi.historico(self.$route.params.id_chat,self.$parent.estado.id_cadastro).then((response)=>{
          let chat = response.data.chat
          self.chat = chat
          
          setTimeout(function(){
            $('body,html').animate({ scrollTop: $('.chat').height() }, 300)
          },
          1000);
          
        })
      },      
      responderMsg(pergunta){           
        this.showAlert=true
        self.modalShow = false
        this.pergunta=pergunta     
        $(".w-input").focus()         
      },
      fecharResposta(){
        this.showAlert=false
        this.pergunta=''
      },
      msgFavoritas(){
        self = this         
        self.favoritas = false
        ChatApi.favoritas(self.$route.params.id_chat,self.$parent.estado.id_cadastro).then((response)=>{
          let chat = response.data.chat
          self.chat = chat
          
          setTimeout(function(){
            $('body,html').animate({ scrollTop: $('.chat').height() }, 300)
          },
          1000);
          
        })
      },
      favoritarMsg(id){
        self = this 
        self.modalShow = false
        self.$socket.emit('mensagens', {         
          id_msg : id,
          tipo:2,
          id_cadastro: self.$parent.estado.id_cadastro ,          
          room: self.$route.params.id_chat
        });
      },  
      desfavoritarMsg(id){
        self = this 
        self.modalShow = false
        self.$socket.emit('mensagens', {         
          id_msg : id,
          tipo:3,
          id_cadastro: self.$parent.estado.id_cadastro ,          
          room: self.$route.params.id_chat
        });
      },   
      escapeHtml(text) {
        if(text)
        return text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
      },
      destacar(texto){        
        self=this
        if(texto){
          var mensagem = self.escapeHtml(texto)                
          var ct = mensagem.split("*").length -1
          if(mensagem.split("*").length>0){
            for (var i = 0; i <= ct; i++) {              
              if(i%2==0 && i>0){              
                mensagem = mensagem.replace("*","<span class='font-weight-bold'>").replace("*","</span>")          
              }            
            }                  
          }
          return mensagem
        }        
      },
      apagarMsg(id,mensagem,data,url=null){
        self = this
        self.modalShow = false
        var before = self.$moment(data,'YYYY.MM.DD HH:mm:ss');
        var now = self.$moment();
        var diff = self.$moment(now - before).format('m')
        // console.log(data,before,now,diff)
        self.$socket.emit('mensagens', {
          url: url,         
          id_msg : id,
          tipo:1,
          id_usuario: self.$parent.estado.id_cadastro ,
          avatar : self.$parent.estado.avatar,
          nome : self.$parent.estado.nome,
          data_insercao:data,
          room: self.$route.params.id_chat
        });
      },
      onFileChange(e){
        self = this
        var file = e.target.files[0]
        let formData = new FormData()
        
        if(file.type=="application/pdf"){
          formData.append('id_chat', self.$route.params.id_chat)             
          formData.append('id_cadastro', self.$parent.estado.id_cadastro)              

          formData.append('file[0]', file )

          ArquivosApi.inserirImagem(formData).then((response) => {

            let data = response.data

            if(data.status=='success'){

              // console.log(data)
              self.$socket.emit('send message', {
                room: self.$route.params.id_chat,
                mensagem: 'Enviado por chat',
                id_usuario: self.$parent.estado.id_cadastro  ,
                avatar :self.$parent.estado.avatar,
                nome : self.$parent.estado.nome,
                url : data.url,
                id_msg:data.id_msg
              })
              $('body,html').animate({ scrollTop: $('.chat').height() }, 300)

            }else{
              self.$swal({
                type: 'error',
                title:'Oops...' ,
                text:'Não é possivel enviar arquivo acima de 2MB.',
                showConfirmButton: false,
                timer: 1500
              })
            }
            self.msg=''
            return false
          })
        }else{
          self.$swal({
            type: 'error',
            title:'Formato inválido!' ,
            text:'Favor enviar apenas : (PDF).',
            showConfirmButton: false,
            timer: 1500
          })
        }
        

        // $.ajax({
        //   url: 'https://v2.convertapi.com/convert/pdf/to/compress?Secret=4xjxzZPonmGoAsJU',
        //   data: formData,
        //   processData: false,
        //   contentType: false,  
        //   method: 'POST',
        //   success: function(data) {           
        //   }
        // })
      },
      onImageChange(e){
        var file = e.target.files[0]
        this.file  = file
        self = this        
        if(file.type=="image/jpeg" || file.type=="image/png"){
        if(file.size>8000){
          new ImageCompressor(file, {
            quality: .2,
            file:'File',
            success(result) {
              var foto = new File([result], "uploaded_file.jpg", { type: "image/jpeg", lastModified: Date.now() })              
              let formData = new FormData()

              formData.append('id_chat', self.$route.params.id_chat)
              formData.append('id_cadastro', self.$parent.estado.id_cadastro)              
              // formData.append('arquivo_tipo', 19)
              formData.append('file[0]', foto)

              ArquivosApi.inserirImagem(formData).then((response) => {
                let data = response.data

                if(data.status=='success'){
                  // console.log(data)
                  self.$socket.emit('send message', {
                    room: self.$route.params.id_chat,
                    mensagem: 'Enviado por chat',
                    id_usuario: self.$parent.estado.id_cadastro  ,
                    avatar :self.$parent.estado.avatar,
                    nome : self.$parent.estado.nome,
                    url : data.url,
                    id_msg:data.id_msg
                  })
                  $('body,html').animate({ scrollTop: $('.chat').height() }, 300)

                }else{
                  self.$swal({
                    type: 'error',
                    title:'Oops...' ,
                    text:'Não é possivel armazenar imagens de alta resolução.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
                self.msg=''
                return false
              })
            },
            error(e) {
              return console.log(e.message)
            },
          })
        }else{
          let formData = new FormData()

          formData.append('id_chat', self.$route.params.id_chat)
          formData.append('id_cadastro', self.$parent.estado.id_cadastro)          
          // formData.append('arquivo_tipo', 19)
          formData.append('file[0]', file)

          ArquivosApi.inserirImagem(formData).then((response) => {
            let data = response.data

            if(data.status=='success'){
              // console.log(data)
              self.$socket.emit('send message', {
                room: self.$route.params.id_chat,
                mensagem: 'Enviado por chat',
                id_usuario: self.$parent.estado.id_cadastro  ,
                avatar :self.$parent.estado.avatar,
                nome : self.$parent.estado.nome,
                url : data.url,
                id_msg:data.id_msg
              })
              $('body,html').animate({ scrollTop: $('.chat').height() }, 300)
            }
            self.msg=''
            return false
          })
        }
        }else{
          self.$swal({
            type: 'error',
            title:'Formato inválido!' ,
            text:'Favor enviar apenas : (JPG, PNG).',
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      clickButton(){
        self = this
        let mensagem = self.msg        
        let emit = {}
        // console.log(self.pergunta)
        if(self.showAlert){     
          if(!self.pergunta.mensagem){
            self.$socket.emit('send message',{
              room: self.$route.params.id_chat,            
              msg_pergunta:self.pergunta.mensagem_r,
              msg_origem:self.pergunta.origem_r,       
              msg_avatar:self.pergunta.avatar_r, 
              msg_nome:self.pergunta.id_cadastro_r, 
              mensagem: mensagem,
              id_usuario: self.$parent.estado.id_cadastro ,
              avatar : self.$parent.estado.avatar,
              nome : self.$parent.estado.nome
            })
          }else{
            self.$socket.emit('send message',{
              room: self.$route.params.id_chat,            
              msg_pergunta:self.pergunta.mensagem,
              msg_origem:self.pergunta.origem,       
              msg_avatar:self.pergunta.avatar, 
              msg_nome:self.pergunta.id_cadastro, 
              mensagem: mensagem,
              id_usuario: self.$parent.estado.id_cadastro ,
              avatar : self.$parent.estado.avatar,
              nome : self.$parent.estado.nome
            })
          }     
           self.showAlert=false

        }else{
          self.$socket.emit('send message',{
            room: self.$route.params.id_chat,
            mensagem: mensagem,
            id_usuario: self.$parent.estado.id_cadastro ,
            avatar : self.$parent.estado.avatar,
            nome : self.$parent.estado.nome
          })
        }           
             
        $('body,html').animate({ scrollTop: $('.chat').height() }, 300);

        self.msg=''
      },
      showModal:function(c,apagar,responder,favoritar) {        
        self = this
          self.apagar = apagar
          self.responder = responder
          self.favoritar = favoritar
          self.infMsg = c 
          self.modalShow=true     
      },
    },
    created(){
      self= this
      self.url ='/home'
      self.id_chat = parseInt(self.$route.params.id_chat)
      var user = self.$parent.estado.acesso
      var id_qualificacao = parseInt(self.$parent.estado.id_qualificacao)
      var id_chat = parseInt(self.$route.params.id_chat)
      
      switch (id_chat) {
        case 1:
          self.titulo = "CCL - Lideres"
          self.tag='ccl_lideres'
          if(user=="user"){
            self.$router.push('/home')
          }
          break;
        case 2:
          self.titulo = "Escala de Elite"    
          self.tag='escala_elite' 
          if((id_qualificacao<9) && (self.$parent.estado.id_cadastro<=0) && (user!="user_admin")) {
            self.$router.push('/home')
          }
          break;
        case 3:
          self.titulo = "VIP - Presidentes"     
          self.tag='vip_presidentes'            
          if((id_qualificacao<8) && (self.$parent.estado.id_cadastro<=0) && (user!="user_admin")) {
            self.$router.push('/home')          
          }
          break;
        case 4:
          self.titulo = "Rede Linear"
          self.tag='rede_linear'
          break;
        case 5:
          self.titulo = "Franquias"   
          self.tag='franquias'              
          if((self.$parent.estado.id_cadastro<=0) && (user!="ccl_franquia" || user!="franquia" )) {
            self.$router.push('/home')          
          }
          break;
        case 6:
          self.titulo = "CCF - Franquias" 
          self.tag='ccl_franquias'                
          if((self.$parent.estado.id_cadastro<=0) && (user!="ccl_franquia")) {
            self.$router.push('/home')          
          }
          break;
      }
      self.$socket.emit('subscribe', self.$route.params.id_chat)
      
      ChatApi.historico(self.$route.params.id_chat,self.$parent.estado.id_cadastro).then((response)=>{
        let chat = response.data.chat
        self.chat = chat
        if(response.data.status=='success'){
          self.chat = chat
        }else{      
          self.chat=[]
        }
        
        setTimeout(function(){
          $('body,html').animate({ scrollTop: $('.chat').height() }, 300)
        },
        1000);
        
      })

    },
    mounted(){
      self = this

      console.log(self.$store.state)
      
      var id_usuario = self.$parent.estado.id_cadastro

      self.$socket.on("reconnect",function(response){        
        if(response.msg){      
          self.$socket.emit('subscribe', self.$route.params.id_chat)    
        }
      });
      self.$socket.on('favoritar', function(response) {
        if(response.room==self.$route.params.id_chat){
          if(id_usuario==response.id_cadastro){            
            self.$swal({
              type: 'success',
              title: response.mensagem,
              showConfirmButton: false,
              timer: 1500
            })

            self.chat.find(d => d.id == response.id_msg).favoritado = response.favoritado

            if(response.favoritado==0 && !self.favoritas){              
              self.chat.find(d => d.id == response.id_msg).url_r = null                  
              self.chat.find(d => d.id == response.id_msg).mensagem_r = response.mensagem
              self.chat.find(d => d.id == response.id_msg).removida = true
            }
          }
          // console.log(self.chat.find(d => d.id == response.id_msg))
        }
      })  
      self.$socket.on('deletar', function(response) {
        if(response.room==self.$route.params.id_chat){          
          self.chat.find(d => d.id == response.id_msg).url_r = null                  
          self.chat.find(d => d.id == response.id_msg).mensagem_r = response.mensagem
          self.chat.find(d => d.id == response.id_msg).removida = true  
        }                      
      })

      self.$socket.on('conversation private post', function(response) {
        if(response.room==self.$route.params.id_chat){
          // console.log(response)
          if(response.msg_id_msg>0){
            self.chat.push({
              data_insercao: response.data_insercao,
              favoritado: 0,
              id: response.id_msg,
              id_cadastro: response.nome,
              id_cadastro_r: response.msg_nome,
              mensagem: response.mensagem,
              mensagem_r: response.msg_pergunta,
              origem: response.id_usuario,
              origem_r: response.msg_origem,
              url: response.url_r || null,
              url_r: response.url  || null,
              avatar : response.avatar      
            })     
          }else{            
            self.chat.push({
              data_insercao: response.data_insercao,
              favoritado: 0,
              id: response.id_msg,
              // id_cadastro: response.msg_nome,
              id_cadastro_r: response.nome,
              // mensagem: response.mensagem,
              mensagem_r: response.mensagem,
              // origem: response.nome,
              origem_r: self.$parent.estado.id_cadastro,
              // url: response.url_r || null,
              url_r: response.url  || null,
              avatar_r : response.avatar         
            })        

          }
          $('body,html').animate({ scrollTop: $('.chat').height() }, 300)

        }
      });
    },
    beforeDestroy(){      
      self = this
      ChatApi.ultimaVisualizacao(self.$parent.estado.id_cadastro,self.id_chat).then((response)=>{
        let visualizacao = response.data;
      })

      self.$socket.emit('unsubscribe', self.$route.params.id_chat)
      self.$socket.removeListener('conversation private post',self.clickButton())
      self.$socket.removeListener('deletar',self.apagarMsg())
      self.$socket.removeListener('favoritar',self.favoritarMsg())
    }
  }
</script>
<style>
  .chat .topo{
    color:#000;
    text-align: center;
    padding: 10px;
    position: fixed;
    top: 65px;
    width: 100%;
    background: #fff;
    z-index: 1;
  }
  .chat .topo .voltar{
    float: left;
  }
  .chat .topo .voltar a{
    color:#008000;
    text-decoration: none !important;
  }
  .chat .topo .favoritas{
    float: right;
  }

  .chat .topo .favoritas button{
    float: right;
    background: #fff;
    color:#008000;
  }

  .participante{
    display: block;
  }
  .image-message {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 48px;
    height: 48px;
    margin-top: 7px;
    border-radius: 25px;
    background: #fff;
  }
  .msg-responder{
    position: fixed !important;
    bottom: 44px;
    width: 100%;
    color:#000 !important;
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important; 
  }
  .responder{
    float:right;
  }
  .responder img{
    width: 40px;
  }
  .chat.column-right.right .chat-text.right .favoritado_dir{
    bottom: 2px;
    position: absolute;
    right: 5px;
    color:yellow;
    font-size: 8px;
  }
  .chat.column-right .chat-text .favoritado_esq{
    bottom: 2px;
    position: absolute;
    left: 5px;
    color:yellow;
    font-size: 8px;
  }
  .chat.column-right .chat-text.right, .chat.column-right .chat-text{
    position:relative;
    max-width: 100%;
  }
  .chat.column-right .chat-text.right .opcoes{
    position: absolute;
    top: 2px;
    left: 7px;
  }
  .chat.column-right .chat-text .opcoes{
    position: absolute;
    top: 2px;
    right: 7px;
  }
  #ddown-dropup label{
    position: relative;
  }
  #ddown-dropup .dropdown-item:focus{
    background-color: #fff;
  }
  #ddown-dropup .dropdown-toggle{
    background: #008000;
    border-color: #008000;
  }
  #ddown-dropup .custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
  }
  .chat p{
    white-space: pre-line;
    margin:0;
    word-break: break-word;
  }
  .chat.column-right .chat-text.right p{
    color:#fff !important;
  }
  .chat textarea{
    resize: none;
  }

</style>
