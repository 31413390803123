import axios from 'axios'

export default () => {
  return axios.create({
    baseURL: 'https://natasha-api.com.br/aktalk/',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': localStorage.getItem('token'),
      //'Content-Type': 'application/json; charset=UTF-8',
	    //'Access-Control-Allow-Origin': '*',
	    //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE',
	    //'Access-Control-Allow-Headers': '*',
      
    },
    "routes": {
		"cors": true
	}
  })
}
