<template>
  	<div id="home" class="boxed">
	  	<div class="grey-bg">       
		    <div class="header-transporent-bg-black">        
		      <!-- HEADER 1 BLACK MOBILE-NO-TRANSPARENT -->
		    	<header id="nav" class="header header-1 black-header">
			        <div class="header-wrapper">
			          <div class="container-m-30 clearfix">         
			            <div class="logo-row">            
			              <div class="logo-container-2">
			                <div class="logo-2">
			                  
			                    <img src="~@/assets/css/novo_layout/images/logo_talk_inicio.png" class="logo-img" alt="Logo">
			                  
			                   <div class="menu-btn-respons-container2">       
			              <a class="fs-primary-nav-trigger" v-if="$parent.isLoggedIn" @click="$parent.logout">
			                <i class="fa fa-sign-out fa-2x" style="color:#fff" title="Sair"></i>
			                <span style="color:#fff">Sair</span>
			              </a>
			            </div>
			                </div>

			              </div> 

			            </div> <!-- FIM LOGO -->
			          </div>
			        <!-- END header-wrapper -->
			        </div>
		      	</header>
		    </div>
		         <!-- PAGE TITLE IMG -->
		    <div class="page-title-cont page-title-large grey-dark-bg page-title-img  blog-single-fullwidth-img">
		        <div class="relative container align-left">
		        	<avatar></avatar>
			       <!--  <div class="post-author-avatar">
			        	<div class="avatar">
			               <img v-if="!$store.state.avatar" :src="$store.state.avatar"/>
			               <img  src="~@/assets/css/novo_layout/images/bg_avatar.png"/>	
			            </div>
			        </div> -->        
			        <div class="row">               
			            <div class="col-md-8">      
			            	<div class="title-fs-45">
			            		{{$parent.estado.nome}}
			                	<!-- <br> -->
			                	<!-- <div class="line-3-100"></div>
			                	<span class="bold">DIAMANTE</span><br> -->
			              	</div>
			            </div>          
			        </div>
		        </div>
		    </div>
		    <div class="pb-4">
		        <router-link to="/chat/1" v-if="$parent.estado.acesso=='user_ccl' || $parent.estado.id_qualificacao===0">
		        	<b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
			            <b-row class="text-center p-3">
			                  <b-col cols="2" class="pr-1">
			                    <img src="~@/assets/css/novo_layout/images/blog/recent/1.png">
			                  </b-col>
			                  <b-col cols="8" class="pr-1 text-left">
			                    <h5 class="m-0">CCL - Líderes</h5>
			                    Conselho consultivo
			                  </b-col >
			                  <b-col cols="2" class="pr-1">
			                    <span class="novasmsg" v-if='grupos[1]' >{{grupos[1]}}</span>
			                    <span class="novasmsg" v-else >0</span>
			                  </b-col>
			            </b-row>
		          	</b-card>
		        </router-link>
		        <router-link to="/chat/2" v-if="$parent.estado.id_qualificacao>=9 || $parent.estado.id_qualificacao===0">
		        	<b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
			            <b-row class="text-center p-3">
			                  <b-col cols="2" class="pr-1">
			                    <img src="~@/assets/css/novo_layout/images/blog/recent/2.png">
			                  </b-col>
			                  <b-col cols="8" class="pr-1 text-left">
			                    <h5 class="m-0">Escala de Elite</h5>
			                    Exclusivo escala de elite
			                  </b-col >
			                  <b-col cols="2" class="pr-1">
			                    <span class="novasmsg" v-if='grupos[2]' >{{grupos[2]}}</span>
			                    <span class="novasmsg" v-else >0</span>
			                  </b-col>
			            </b-row>
		          	</b-card>
		        </router-link>
		        <router-link to="/chat/3" v-if="$parent.estado.id_qualificacao>=8 || $parent.estado.id_qualificacao===0">
		        	<b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
			            <b-row class="text-center p-3">
			                  <b-col cols="2" class="pr-1">
			                    <img src="~@/assets/css/novo_layout/images/blog/recent/3.png">
			                  </b-col>
			                  <b-col cols="8" class="pr-1 text-left">
			                    <h5 class="m-0">VIP - Presidentes</h5>
			                    Exclusivo presidentes
			                  </b-col >
			                  <b-col cols="2" class="pr-1">
			                    <span class="novasmsg" v-if='grupos[3]' >{{grupos[3]}}</span>
			                    <span class="novasmsg" v-else >0</span>
			                  </b-col>
			            </b-row>
		          	</b-card>
		        </router-link>
		        <router-link to="/chat/5" v-if="$parent.estado.acesso=='franquia' ||$parent.estado.acesso=='ccl_franquia' || $store.state.id_qualificacao===0">
		          	<b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
			            <b-row class="text-center p-3">
			                  <b-col cols="2" class="pr-1">
			                    <img src="~@/assets/css/novo_layout/images/blog/recent/6.png">
			                  </b-col>
			                  <b-col cols="8" class="pr-1 text-left">
			                    <h5 class="m-0">Franquias</h5>
			                    Exclusivo Franquias
			                  </b-col >
			                  <b-col cols="2" class="pr-1">
			                    <span class="novasmsg" v-if='grupos[5]' >{{grupos[5]}}</span>
			                    <span class="novasmsg" v-else >0</span>
			                  </b-col>
			            </b-row>
		          	</b-card>
		        </router-link>
		        <router-link to="/chat/6" v-if="$parent.estado.acesso=='ccl_franquia' || $parent.estado.id_qualificacao===0">
		          	<b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
			            <b-row class="text-center p-3">
			                  <b-col cols="2" class="pr-1">
			                    <img src="~@/assets/css/novo_layout/images/blog/recent/6.png">
			                  </b-col>
			                  <b-col cols="8" class="pr-1 text-left">
			                    <h5 class="m-0">CCF - Franquias</h5>
			                    Esclusivo Conselho Franquias
			                  </b-col >
			                  <b-col cols="2" class="pr-1">
			                    <span class="novasmsg" v-if='grupos[6]' >{{grupos[6]}}</span>
			                    <span class="novasmsg" v-else >0</span>
			                  </b-col>
			            </b-row>
		          	</b-card>
		        </router-link>
		        <router-link to="/chatredelinear/" v-if="$parent.estado.id_qualificacao>=2 || $parent.estado.id_qualificacao===0 ">
		          	<b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
			            <b-row class="text-center p-3">
			                  <b-col cols="2" class="pr-1">
			                    <img src="~@/assets/css/novo_layout/images/blog/recent/4.png">
			                  </b-col>
			                  <b-col cols="8" class="pr-1 text-left">
			                    <h5 class="m-0">Rede Linear</h5>
			                    Envio de Mensagens
			                  </b-col >
			                  <b-col cols="2" class="pr-1">
			                    <span class="novasmsg" v-if='grupos[4]' >{{grupos[4]}}</span>
			                    <span class="novasmsg" v-else >0</span>
			                  </b-col>
			            </b-row>
		          	</b-card>
		        </router-link>
		    </div>
		</div><!-- End BG -->
<!-- 		<div>
			<a @click="openKaren">
				<b-card class="shadow-sm" no-body style="background-color:#e8edf9; border-radius:0; border:0">
					<b-row class="text-center p-3">
				        <b-col cols="2" class="pr-1">
				        	<font-awesome-icon icon="folder" size="3x" style="color: #767c7f;" />
				        </b-col>
				        <b-col cols="8" class="pr-1 text-left">
				        	<h5 class="m-0">Karen</h5>
				        	Dúvidas gerais
				        </b-col >
				        <b-col cols="2" class="pr-1">
				        	<font-awesome-icon icon="info-circle" size="lg" style="color: #767c7f;" />
				        </b-col>
					</b-row>
				</b-card>
			</a>		 	
		</div> -->
	</div>
</template>

<script>
// import '@/assets/js/landbot-widget-1.0.0.js'
import ChatApi from '@/api/chat-api'
import Avatar from '@/components/Avatar'
import $ from 'jquery'
// const karen = new LandbotPopup({
// 	index: 'https://landbot.io/u/H-53292-9K4GSB6VWMGI205A/index.html',
// 	open: false,
// 	proactive: false,
// 	font: 'Montserrat',
// 	launcherOptions: {
// 		enable: false,
// 	},
// });
// const luciano = new LandbotPopup({
// 	index: 'https://landbot.io/u/H-90522-9SY3LU6X8LSUKRGL/index.html',
// 	open: false,
// 	proactive: false,
// 	font: 'Montserrat',
// 	launcherOptions: {
// 		enable: false,
// 	},
// });

export default {
  name: 'home',
  data(){
  	return{
  		id_chat:[],
  		grupos:[0,0,0,0,0],
  	}
  },
  components:{
  	Avatar
  },
  methods:{  	
  	openKaren(){
		karen.exec('landbot-widget-open')
  	},
  	openLuciano(){
		luciano.exec('landbot-widget-open')
  	},
  	editarAvatar(){
  		this.$swal({
		  title: 'Sweet!',
		  text: 'Modal with a custom image.',
		  imageUrl: 'http://cdn.shopify.com/s/files/1/0191/7850/products/RICKMORTY_39_-_COVER_A_FNL_WEB_1024x1024.jpg?v=1530034748',
		  imageWidth: 400,
		  imageHeight: 200,
		  imageAlt: 'Custom image',
		  animation: false,
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Alterar'
		}).then((result)=>{
		})
  	}
  },
  created(){
  	self = this
  	setTimeout(function(){
        ChatApi.naoVisualizadas(self.$parent.estado.id_cadastro).then((response)=>{ 	

			let data = response.data	 	
			// console.log(response)
			var chats = []	

			$.each(data, function(position, chat) {
				chats[chat.id_chat]=chat.msgs
			})
			ChatApi.naoVisualizadasRedeLinear(self.$parent.estado.id_cadastro).then((r)=>{ 
				let data2 = r.data	 	
				// console.log(data.msgs)
				self.redeLinear = data2.msgs
				chats[data2.id_chat] = data2.msgs
				self.grupos = chats
			})			
		})
		
    },1000);
    // console.log(self.grupos)
  },
  mounted(){	
  	self=this
  	$('body,html').animate({ scrollTop: 0 }, 300)

  	self.$socket.on('group', function(response) {  		
  		var a =[]
  		var b = 0
  		a = Array.from(self.grupos, item => item || 0);
  		b = self.redeLinear;
  		
  		var data = JSON.parse(JSON.stringify(response))

  		if(data.id_chat){

  			switch (data.id_chat) {
	  			case 1:
	  				a[1]++
	  				break;
	  			case 2:
	  				a[2] += 1
	  				break;
	  			case 3:
	  				a[3] += 1
	  				break;
	  			case 4:
	  				b += 1
	  				break;
	  			case 5:
	  				a[5] += 1
	  				break;
	  			case 6:
	  				a[6] += 1
	  				break;
	  		}
  		}
  		self.grupos = a
  		self.redeLinear = b
  	})
  },
  beforeDestroy(){
  	self = this
  	self.$socket.removeListener('group')    
  }
}
</script>
<style type="text/css">
	.home{
		padding-top: 70px;
	}
	.novasmsg{
		color:#d30c0c;
		font-weight: 700;
	}
	.suporte_akmos{
		position: absolute;
    	width: 100%;
    	bottom: 0;
	}
	.home .src-image {
	  display: none;
	}

	.home .card {
	  overflow: hidden;
	  position: relative;
	  border: 1px solid #CCC;
	  border-radius: 8px;
	  text-align: center;
	  padding: 0;
	  background-color: #284c79;
	  color: rgb(136, 172, 217);
	}

	.home .card .header-bg {
	  /* This stretches the canvas across the entire hero unit */
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 70px;
	  border-bottom: 1px #FFF solid;
	  /* This positions the canvas under the text */
	  z-index: 1;
	}
	.home .card .avatar {
	  position: relative;
	  margin-top: 15px;
	  z-index: 100;
	}

	.home .card .avatar img {
	  width: 100px;
	  height: 100px;
	  -webkit-border-radius: 50%;
	  -moz-border-radius: 50%;
	  border-radius: 50%;
	  border: 5px solid rgba(0,0,30,0.8);
	}
</style>
