import axiosConfig from '@/config/axios'
import axios from 'axios'

export default {

	login(ak_login,ak_senha,ak_token_dev){
		return axios.get('https://natasha-api.com.br/aktalk/login/index',{
			params:{
				ak_login,
				ak_senha,
				ak_token_dev
			}			
		});
	},
	persist(token){
		return axiosConfig().get('login/persist',{
			params:{
				token
			}			
		});
	}
}