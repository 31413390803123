import Vue from 'vue'
import Router, {RedirectOption} from 'vue-router';
import store from './store'
import Login from './components/Login.vue'
import NovoLayout from './components/NovoLayout.vue'
import Paginas from '@/components/router'

Vue.use(Router)

const REDIRECT_TIME_LIMIT = 3600000

const redirect = () => {
  const defaultRoute = '/home';
  const local = JSON.parse(window.localStorage.getItem('vuex'));
  const isIos = () => window.navigator.userAgent.indexOf('iPhone') !== -1;
  // @ts-ignore
  const isStandalone = () => window.navigator.standalone;

  if (!local) {
    return defaultRoute;
  }

  const {route, lastLaunch} = local;
  const now = new Date().valueOf();

  if (isIos() && isStandalone() && now - lastLaunch < REDIRECT_TIME_LIMIT) {
    return route.path;
  }

  return defaultRoute;
}


const router = new Router({
// mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      // redirect: (to: RedirectOption) => redirect(),
      redirect: to => redirect(),
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/novolayout',
      name: 'novolayout',
      component: NovoLayout
    },
    Paginas
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})
export default router