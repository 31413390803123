import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Auth from '@/api/auth-api'
// import createPersistedState from 'vuex-persistedstate'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    route: state.route,
    lastLaunch: new Date().valueOf(),
  }),
});

export default new Vuex.Store({
  state: {
  	status: '',
    token: localStorage.getItem('token') || '',
    id_cadastro:'',
    id_qualificacao:'',
    acesso:'',
    nome:'',
    avatar:''
  },
  plugins: [vuexLocal.plugin],
  // plugins: [createPersistedState()],
  getters : {
	isLoggedIn: state => !!state.token,
	authStatus: state => state.status,
	authCadastro: state => state.id_cadastro,
	authQualificacao: state => state.id_qualificacao,
	authAcesso: state => state.acesso,
	authNome: state => state.nome,
	authAvatar: state => state.avatar,
  },
  mutations: {
  	auth_qualificacao(state,id_qualificacao){
  		state.id_qualificacao = id_qualificacao
  	},
	auth_cadastro(state,id_cadastro){
		state.id_cadastro = id_cadastro
  	},
  	auth_request(state){
	    state.status = 'loading'
	},
	auth_acesso(state,acesso){
		state.acesso = acesso
	},
	auth_nome(state,nome){
		state.nome = nome
	},
	auth_avatar(state,avatar){
		state.avatar = avatar
	},
	auth_success(state,token){
	    state.status 		= 'success'
	    state.token 	    = token
	},
    auth_error(state){
    	state.status = 'Usuário ou senha inválido.'
    },
    logout(state){
    	state.status = ''
    	state.token = ''
    	state.acesso = ''
    	state.nome = ''
    	state.id_cadastro = ''
    	state.id_qualificacao= ''
    	state.avatar=''
    },
  },
  actions: {
  	login({commit}, user){
	    return new Promise((resolve, reject) => {
	      commit('auth_request')

	      Auth.login(user.ak_login,user.ak_senha,user.ak_token_dev)
	      .then(resp => {

	      	if(resp.data.success){
	      		const token 			= resp.data.token
		        const acesso 			= resp.data.acesso
		        const nome 				= resp.data.nome
		        const id_cadastro 		= resp.data.id_cadastro
		        const id_qualificacao 	= resp.data.id_qualificacao
		        const avatar 	        = resp.data.avatar

		        localStorage.setItem('token', token)
		        localStorage.setItem('estado',JSON.stringify({
			        status: 'success',
			        token: token,
			        id_cadastro:id_cadastro,
			        id_qualificacao:id_qualificacao,
			        acesso:acesso,
			        nome:nome,
			        avatar:avatar
			    }))

		        commit('auth_success', token)	
		        commit('auth_acesso', acesso)
		        commit('auth_nome', nome)
		        commit('auth_avatar', avatar)
		        commit('auth_cadastro', id_cadastro)
		        commit('auth_qualificacao', id_qualificacao)
		        
		        resolve(resp)
	      	}
	        else{
	        	commit('auth_error')	        	
	        }
	      })
	      .catch(err => {
	        commit('auth_error')
	        localStorage.removeItem('token')
	        reject(err)
	      })
	    })
	},
	persist({commit}, user){
	    return new Promise((resolve, reject) => {
	      commit('auth_request')

	      Auth.persist(user.token)
	      .then(resp => {

	      	if(resp.data.success){
	      		const token 			= resp.data.token
		        const acesso 			= resp.data.acesso
		        const nome 				= resp.data.nome
		        const id_cadastro 		= resp.data.id_cadastro
		        const id_qualificacao 	= resp.data.id_qualificacao
		        const avatar 	        = resp.data.avatar

		        localStorage.setItem('token', token)
		        localStorage.setItem('vuex',JSON.stringify({
			        status: 'success',
			        token: token,
			        id_cadastro:id_cadastro,
			        id_qualificacao:id_qualificacao,
			        acesso:acesso,
			        nome:nome,
			        avatar:avatar
			    }))

		        commit('auth_success', token)	
		        commit('auth_acesso', acesso)
		        commit('auth_nome', nome)
		        commit('auth_avatar', avatar)
		        commit('auth_cadastro', id_cadastro)
		        commit('auth_qualificacao', id_qualificacao)
		        
		        resolve(resp)
	      	}
	        else{
	        	commit('auth_error')	
	        	localStorage.removeItem('token')
	        	reject(err)        	
	        }
	      })
	      .catch(err => {
	        commit('auth_error')
	        localStorage.removeItem('token')
	        reject(err)
	      })
	    })
	},
	avatar({commit},fota){
	  return new Promise((resolve, reject) => {
	    commit('auth_avatar',fota)	    
	    resolve()
	  })
	},
	logout({commit}){
	  return new Promise((resolve, reject) => {
	    commit('logout')
	    localStorage.removeItem('token')
	    localStorage.removeItem('vuex')
	    delete axios.defaults.headers.common['Authorization']
	    resolve()
	  })
	}
  },  
})

