<template>
  <div id="home" class="boxed ">
    <div class="grey-bg">       
     <div class="header-transporent-bg-black">        
      <!-- HEADER 1 BLACK MOBILE-NO-TRANSPARENT -->
      <header id="nav" class="header header-1 black-header">
        <div class="header-wrapper">
          <div class="container-m-30 clearfix">         
            <div class="logo-row">            
              <div class="logo-container-2">
                <div class="logo-2">
                  <a href="akt.html" class="clearfix">
                    <img src="~@/assets/css/novo_layout/images/logo_talk_inicio.png" class="logo-img" alt="Logo">
                  </a>
                   <div class="menu-btn-respons-container2">       
              <a class="fs-primary-nav-trigger" href="#">
                <i class="fa fa-sign-out fa-2x" style="color:#fff" title="Sair"></i>
              </a>
            </div>
                </div>

              </div> 

            </div> <!-- FIM LOGO -->
          </div>
        <!-- END header-wrapper -->
        </div>
      </header>
      </div>
         <!-- PAGE TITLE IMG -->
      <div class="page-title-cont page-title-large grey-dark-bg page-title-img  blog-single-fullwidth-img">
        <div class="relative container align-left">
      
          <div class="post-author-avatar">
            <img alt="ava" src="~@/assets/css/novo_layout/images/avatar.png" >
          </div>        
          <div class="row">               
            <div class="col-md-8">      
              <div class="title-fs-45">
                EDSON PEREIRA<br>
                <div class="line-3-100"></div>
                <span class="bold">DIAMANTE</span><br>
              </div>
            </div>          
          </div>
        </div>
      </div>

      <div class="pb-4">
        <router-link to="/chat/1">
        <b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
          <div class="">
            <b-row class="text-center p-3">
              <b-col cols="2" class="pr-1">
                <!-- <font-awesome-icon icon="user-circle" size="3x" style="color: #007bff;" /> -->
                <img src="~@/assets/css/novo_layout/images/blog/recent/1.png" alt="CCL LIDERES" >
              </b-col>
              <b-col cols="8" class="pr-1 text-left">
                <h5 class="m-0">CCL - Lideres</h5>
                Conselho consultivo
              </b-col >
              <b-col cols="2" class="pr-1">
                <span class="novasmsg" >0</span>
              </b-col>
            </b-row>
          </div>
         
        </b-card>
      </router-link>
      <router-link to="/chat/2">
        <b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
          <b-row class="text-center p-3">
                <b-col cols="2" class="pr-1">
                  <!-- <font-awesome-icon icon="user-circle" size="3x" style="color: #007bff;" /> -->
                  <img src="~@/assets/css/novo_layout/images/blog/recent/2.png" alt="CCL LIDERES" >
                </b-col>
                <b-col cols="8" class="pr-1 text-left">
                  <h5 class="m-0">Escala de Elite</h5>
                  Exclusivo escala de elite
                </b-col >
                <b-col cols="2" class="pr-1">
                  <span class="novasmsg" >0</span>
                </b-col>
          </b-row>
        </b-card>
      </router-link>
      <router-link to="/chat/3">
        <b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
          <b-row class="text-center p-3">
                <b-col cols="2" class="pr-1">
                  <!-- <font-awesome-icon icon="user-circle" size="3x" style="color: #007bff;" /> -->
                  <img src="~@/assets/css/novo_layout/images/blog/recent/3.png" alt="CCL LIDERES" >
                </b-col>
                <b-col cols="8" class="pr-1 text-left">
                  <h5 class="m-0">VIP - Presidentes</h5>
                  Exclusivo presidentes
                </b-col >
                <b-col cols="2" class="pr-1">
                  <span class="novasmsg" >0</span>
                </b-col>
          </b-row>
        </b-card>
      </router-link>
      <router-link to="/chat/5" >
        <b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
          <b-row class="text-center p-3">
                <b-col cols="2" class="pr-1">
                  <!-- <font-awesome-icon icon="user-circle" size="3x" style="color: #007bff;" /> -->
                  <img src="~@/assets/css/novo_layout/images/blog/recent/6.png">
                </b-col>
                <b-col cols="8" class="pr-1 text-left">
                  <h5 class="m-0">Franquias</h5>
                  -------------
                </b-col >
                <b-col cols="2" class="pr-1">
                  <span class="novasmsg" >0</span>
                </b-col>
          </b-row>
        </b-card>
      </router-link>
      <router-link to="/chat/6" >
        <b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
          <b-row class="text-center p-3">
                <b-col cols="2" class="pr-1">
                  <!-- <font-awesome-icon icon="user-circle" size="3x" style="color: #007bff;" /> -->
                  <img src="~@/assets/css/novo_layout/images/blog/recent/6.png" alt="CCL LIDERES" >
                </b-col>
                <b-col cols="8" class="pr-1 text-left">
                  <h5 class="m-0">CCL - Franquias</h5>
                  -------------
                </b-col >
                <b-col cols="2" class="pr-1">
                  <span class="novasmsg" >0</span>
                </b-col>
          </b-row>
        </b-card>
      </router-link>
      <router-link to="/chatredelinear/" >
        <b-card class="m-3 shadow-sm bg-white rounded" no-body  bg-variant="light" text-variant="dark">
          <b-row class="text-center p-3">
                <b-col cols="2" class="pr-1">
                  <!-- <font-awesome-icon icon="user-circle" size="3x" style="color: #007bff;" /> -->
                  <img src="~@/assets/css/novo_layout/images/blog/recent/4.png" alt="CCL LIDERES" >
                </b-col>
                <b-col cols="8" class="pr-1 text-left">
                  <h5 class="m-0">Rede Linear</h5>
                  Envio de Mensagens
                </b-col >
                <b-col cols="2" class="pr-1">
                  <span class="novasmsg" >0</span>
                </b-col>
          </b-row>
        </b-card>
      </router-link>
    </div>
    </div><!-- End BG --> 
  </div><!-- End wrap -->   
</template>
  
<script>
  import $ from 'jquery'
  export default {
    data(){
      return {
        id_cadastro : "",
        senha : ""
      }
    },
    methods: {
      login: function () {
        let ak_login = this.id_cadastro 
        let ak_senha = this.senha
        let ak_token_dev = 'yy8RPMa2'
        let service ='aktalk'

        this.$store.dispatch('login', { ak_login, ak_senha, ak_token_dev, service })
       .then(() => this.$router.push('/home'))
       .catch(err => console.log(err))
      }
    },
    mounted(){      
  
    
    }
  }
</script>