import axios from '@/config/axios'

export default {

	historico(id_chat,id_cadastro){
		return axios().get('chat/historico',{
			params:{
				id_chat,
				id_cadastro
			}
		});
	},
	favoritas(id_chat,id_cadastro){
		return axios().get('chat/favoritas',{
			params:{
				id_chat,
				id_cadastro
			}
		});
	},
	ultimaVisualizacao(id_cadastro,id_chat){
		return axios().get('chat/ultimavisualizacao',{
			params:{
				id_cadastro,
				id_chat
			}
		});
	},
	naoVisualizadas(id_cadastro){
		return axios().get('chat/naovisualizadas',{
			params:{
				id_cadastro
			}
		});
	},
	naoVisualizadasRedeLinear(id_cadastro){
		return axios().get('chat/naovisualizadasredelinear',{
			params:{
				id_cadastro
			}
		});
	},
	historicoRedeLinear(id_cadastro){
		return axios().get('chat/historicoredelinear',{
			params:{
				id_cadastro
			}
		});
	},
	enviarMensagem(id_cadastro,id_chat,mensagem){
		return axios().get('chat/enviarmensagem',{
			params:{
				id_cadastro,
				id_chat,
				mensagem
			}
		});
	}
}