<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>  
  import "./assets/js/novo_layout/jquery.appear.js"
  import "./assets/js/novo_layout/owl.carousel.min.js"
  import "./assets/js/novo_layout/text-rotator.js"
  
  export default {
    name: 'app',
    data () {
      return {
        msg: '',        
      }
    },
    mounted(){
      // console.log(window)
    }    
  }
</script>

<style type="text/css">
  
  @import './assets/css/novo_layout/text-rotator.css';
  @import './assets/css/novo_layout/style2.css';
  @import './assets/css/novo_layout/icons-fonts.css';
  @import './assets/css/novo_layout/framework.css';  

  @import './assets/css/chat/normalize.css';
  @import './assets/css/chat/webflow.css';
  @import './assets/css/chat/styles.css';
  @import './assets/css/novo_layout/bootstrap.min.css';

</style>