import Vue 		from 'vue'
import { sync } from 'vuex-router-sync'
import App 		from './App.vue'
import router 	from './router'
import store 	from './store'
import Axios 	from 'axios'
import './registerServiceWorker'
// import OneSignal from './assets/js/OneSignalSDK.js'
import VeeValidate 	from 'vee-validate'
import VueSweetalert2 from 'vue-sweetalert2'
import VueSocketio 	from 'vue-socket.io'
import BootstrapVue from 'bootstrap-vue'
import { library } 	from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrash,faAngleDown,faReply,faCamera,faTimesCircle,faStar,faFile,faCoffee,faUserCircle,faFolder,faShoppingCart,faAddressBook,faInfoCircle,faImage } from '@fortawesome/free-solid-svg-icons'

// import css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

library.add(faTrash,faAngleDown,faReply,faCamera,faTimesCircle,faStar,faFile,faCoffee,faUserCircle,faFolder,faShoppingCart,faAddressBook,faInfoCircle,faImage)

Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.use(OneSignal)
Vue.use(BootstrapVue)
Vue.use(VeeValidate)
Vue.use(VueSweetalert2)
Vue.use(require('vue-moment'));

// Vue.use(VueSocketio, 'http://192.168.1.149:3001');
Vue.use(VueSocketio, 'https://impakt60app.com.br:3001');

Vue.prototype.$https = Axios;
const token = localStorage.getItem('user-token')
if (token) {
  Vue.prototype.$https.defaults.headers.common['Authorization'] = token
}

sync(store, router);

// Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
