<template>
  <div v-if="!isMobile" id="wrap" class="login" data-stellar-background-ratio="0.5"
  style="
    width: 100%;
    background-position: 50%;">
    <div class="row">              
      <img src="~@/assets/css/novo_layout/images/logo_talk.png" style="padding: 6px 0 0 20px;width: 15%;height: 10%;" alt="Logo">      
      
      <div class="col-md-12">
        <div class="col-md-12 text-center">
          <h1 class="cd-headline zoom light-72-wide sm-mt-20  sm-mb-20"> 
            <span class="cd-words-wrapper waiting">
                <b class="is-visible" style="text-align: center;">NOSSO NOVO</b>
                <b style="text-align: center;">JEITO DE <img src="~@/assets/css/novo_layout/images/blog/post-prev-1.png" width="130"style="position:absolute;"></b>
                <b style="text-align: center;">COMUNICAR!</b>
            </span>
          </h1>                                     
          <div class="norm-16-wide sm-mb-20">
            INOVADOR<span class="slash-divider-10">/</span>OUSADO<span class="slash-divider-10">/</span>E TUDO NOSSO!
          </div> 
        </div>
      </div>
    </div>
  </div>  
  <div v-else id="wrap" class="login" data-stellar-background-ratio="0.5">    
    <div class=" sm-content-cont js-height-fullscr">
      <div class="sm-cont-middle" >                    
            <img src="~@/assets/css/novo_layout/images/blog/post-prev-1.png" class="logo-img" height="150" alt="Logo">
            <div class="container sm-content-cont">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1 class="cd-headline zoom light-72-wide sm-mt-20  sm-mb-20">
                    <span>AKTALK:</span> 
                    <span class="cd-words-wrapper waiting">
                        <b class="is-visible">NOSSO NOVO</b>
                        <b>JEITO DE</b>
                        <b>COMUNICAR!</b>
                    </span>
                  </h1>                                     
                  <div class="norm-16-wide sm-mb-20">
                    INOVADOR<span class="slash-divider-10">/</span>OUSADO<span class="slash-divider-10">/</span>E TUDO NOSSO!
                  </div> 
                </div>
              </div>
              <form class="form-signin" @submit.prevent="login">
                <div class="row">
                  <div class="col-md-12 left-50 right-50 top-0" style="background: white;opacity: 0.4;border-radius: 16px;margin: 15px;">
                    <div class="input-style has-icon input-style-1 input-required">
                      <i class="input-icon fa fa-user font-11"></i>
                      <span>ID AKMOS</span>
                      <em>(obrigatório)</em>
                      <input v-model="id_cadastro" type="text" placeholder="ID Akmos" required autofocus>                    
                    </div>
                    <div class="input-style has-icon input-style-1 input-required">
                      <i class="input-icon fa fa-lock font-11"></i>
                      <span>Senha</span>
                      <em>(obrigatório)</em>
                      <input v-model="senha" type="password" placeholder="Senha" required>
                    </div>                      
                  </div>
                  <div class="col-md-12 span12 text-center mb-3" style="">{{$store.state.status}}</div>
                  <button class="button button-center-medium button-m button-round-large bg-highlight shadow-large bottom-40" type="submit">
                    ENTRAR
                  </button>
                  <div class="clearfix"></div>
                </div>
              </form>
            </div>
                              
      </div><!-- End BG -->
    </div> 
  </div>
</template>

<script>
  import $ from 'jquery'
  import MobileDetect from 'mobile-detect'
  export default {
    data(){
      return {
        id_cadastro : "",
        senha : "",
        isMobile:true
      }
    },
    methods: {
      login: function () {
        let ak_login = this.id_cadastro 
        let ak_senha = this.senha
        let ak_token_dev = 'yy8RPMa2'
        let service ='aktalk'

        this.$store.dispatch('login', { ak_login, ak_senha, ak_token_dev, service })
       .then(() => this.$router.push('/home'))
       .catch(err => console.log(err))
      }
    },
    created(){      

      var md = new MobileDetect(window.navigator.userAgent)
      var mobile  = md.mobile()!==null ? true : false
      var phone   = md.phone()!==null ? true : false
      var tablet  = md.tablet()!==null ? true : false
      // console.log(mobile,phone,tablet)
      if ( mobile && phone){
        this.isMobile=true
      }
    },
    mounted(){
      $(".js-height-fullscr").height($(window).height())
    }
  }
</script>
<style type="text/css"> 
</style>