import Vue from 'vue'
import Container from '@/components/Container'
import Home from '@/components/Home.vue'
import Chat from '@/components/Chat.vue'
import ChatRedeLinear from '@/components/ChatRedeLinear.vue'

export default {
  path: '/',
  name: 'container',
  component: Container,
  children: [    
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/chat/:id_chat',
      name: 'Chat',
      component: Chat,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/chatredelinear/',
      name: 'Chat Rede Linear',
      component: ChatRedeLinear,
      meta: { 
        requiresAuth: true
      }
    }
  ]
}
