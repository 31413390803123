//import axios from '@/config/axios'
import axios from 'axios'
export default {

	inserirImagem(formData){

		return axios.post('https://natasha-api.com.br/aktalk/arquivo/upload',formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token'),
            }
        })
	},
	uploadAvatar(formData){

		return axios.post('https://natasha-api.com.br/aktalk/arquivo/avatar',formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token'),
            }
        })
	},
}