<template>
  <div class="avatar_component">
    <form @submit.prevent="submitForm">
      <div class="post-author-avatar">
        <div class="avatar">
          <img v-if="$store.state.avatar" :src="$store.state.avatar"/>
          <img else src="~@/assets/css/novo_layout/images/bg_avatar.png"/> 
        </div>
        <div class="rank-label-container">
          <label class="custom-file">
            <input 
            type="file" 
            @change="onFileChange" 
            v-validate="'required|mimes:image/jpeg,image/png,image/jpg|size:10000'"
            class="custom-file-input">
            <span class="custom-file-control">Alterar Avatar</span> </label>
        </div>
        <b-modal v-model="showModal" size="sm" centered hide-footer hide-header >
          <div class="profile-header-img" style="padding: 20px;">
            <div class="media flex-column">
              <span class="message_userpic mx-auto user-big-image">
                <img class="d-flex mr-3" style="height:100%;" :src="image">
              </span>
            </div>
          </div>                             
          <div class="text-center mb-4">
            <button type="button" @click="removeImage" class="btn btn-danger btn-block">Remover</button>
            <button :disabled="disabled" type="submit" class="btn btn-success btn-block">SALVAR</button>
          </div>    
        </b-modal>
      </div>
    </form> 
  </div>
</template>

<script>

import ArquivosApi from '@/api/arquivos-api'
import ImageCompressor from 'image-compressor.js'

export default {
  name: 'Upload',
  props:['id','titulo'],
  data () {
    return {
      disabled:false,
      image:null,
      msg:'',
      file:[],
      user:{},
      avatar:'',
      showModal:false,
    }
  },
  computed:{
    msgErro: function(){
      return this.msg
    }
  },
  methods:{

    onFileChange(e) {      
      self = this
      var files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return;      
      self.createImage(files[0])

      self.image = true
      self.showModal = true
      self.file  = files    

    },
    createImage(file) {
      var image  = new Image()
      var reader = new FileReader()
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result
      };      
      reader.readAsDataURL(file)
    },
    removeImage() {
      self = this
      self.image = false
      self.showModal= false
      self.file = null
      self.msg =''      

    },
    submitForm() {     
      self = this
      self.disabled=true
      if(self.image){
        self.msg = ""   
        self.$validator.validateAll().then(res=>{
          if(res) {

            self.msg = ""            

            let id_cadastro = self.$store.state.id_cadastro

            if(self.file[0].size>8000){

              new ImageCompressor(self.file[0], {
                quality: .2,
                file:'File',
                success(result) {
                  var foto = new File([result], "uploaded_file.jpg", { type: "image/jpeg", lastModified: Date.now() })

                  let formData = new FormData()

                  formData.append('id_cadastro', id_cadastro )
                  formData.append('arquivo_tipo', 'avatar' )
                  formData.append('file[0]', foto)

                  ArquivosApi.uploadAvatar(formData).then((response) => {
                    let data = response.data

                    if(data.status=='success'){

                      self.avatar = data.avatar
                      self.$store.dispatch('avatar', data.avatar)
                       .then(() => self.$router.push('/home'))
                       .catch(err => console.log(err))
                      // localStorage.setItem('user',JSON.stringify(self.user))

                      self.$swal({
                        position: 'center',
                        type: 'success',
                        title: data.msg,
                        showConfirmButton: false,
                        timer: 1500
                      })

                      self.$router.push('/home')
                    }else{

                      self.$swal({
                        position: 'center',
                        type: 'error',
                        title: data.msg,
                        showConfirmButton: false,
                        timer: 2000
                      })
                      self.disabled=false
                    }
                  }).catch((e) => {console.log('error', e)})
                },
                error(e) {
                  return console.log(e.message)
                }
              })

            }else{
              let formData = new FormData()

              formData.append('id_cadastro', id_cadastro )
              formData.append('arquivo_tipo', 'avatar' )
              formData.append('file[0]', self.file[0])


              ArquivosApi.uploadAvatar(formData).then((response) => {
                let data = response.data

                if(data.status=='success'){

                  self.avatar = data.avatar
                  
                  self.$store.dispatch('avatar', data.avatar)
                   .then(() => self.$router.push('/home'))
                   .catch(err => console.log(err))                  

                  self.$swal({
                    position: 'center',
                    type: 'success',
                    title: data.msg,
                    showConfirmButton: false,
                    timer: 1500
                  })

                  self.$router.push('/home')
                }else{

                  self.$swal({
                    position: 'center',
                    type: 'error',
                    title: data.msg,
                    showConfirmButton: false,
                    timer: 2000
                  })
                  self.disabled=false
                }
              }).catch((e) => {console.log('error', e)})  
            }    
            self.showModal=false
            self.disable=false
          } else {                    
            self.msg ='Formato inválido'
            self.disabled=false
          }
        })
      }else {      
        self.msg ='Favor selecionar uma imagem.'
        self.disabled=false
      }
    }, 
  },
  mounted(){ 
    // this.avatar = this.$store.state.avatar!=null ? true : false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.user-big-image{
  border:1px solid #eee;
}
</style>
